.bus-time-update-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.bus-time-update-paper {
  padding: 20px;
  margin-bottom: 20px;
}

.bus-time-list {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
}

.bus-time-list-item {
  transition: background-color 0.2s ease;
  border-left: 3px solid transparent;
}

.bus-time-list-item:hover {
  background-color: rgba(128, 0, 128, 0.05);
  border-left: 3px solid #800080;
}

.bus-time-edit-form {
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
}

.review-notice {
  font-style: italic;
  color: #555;
  padding: 8px 0;
  border-top: 1px dashed rgba(128, 0, 128, 0.3);
  margin-top: 8px;
}

@media (max-width: 600px) {
  .bus-time-update-container {
    padding: 10px;
  }
  
  .bus-time-update-paper {
    padding: 15px;
  }
} 