/* Timeline styling */
.timeline-container {
  position: relative;
  padding-left: 1.5rem;
}

.timeline-container::before {
  content: '';
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #e0e0e0;
  z-index: 1;
}

.timeline-item {
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 2rem;
}

/* Timeline node styling */
.timeline-node {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  z-index: 3;
  box-shadow: 0 0 0 3px #fff;
}

.timeline-node.past {
  background-color: #a7a7a7;
  border: none;
  width: 12px;
  height: 12px;
}

.timeline-node.current {
  background-color: #5d4a7d;
  width: 26px;
  height: 26px;
  left: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 3px #fff;
  z-index: 4;
  border-radius: 50%;
}

.timeline-node.future {
  background-color: #fff;
  border: 2px solid #5d4a7d;
  opacity: 0.7;
  width: 12px;
  height: 12px;
}

.bus-icon {
  color: white;
  font-size: 14px !important;
  opacity: 1 !important;
}

/* Timeline content styling */
.timeline-content {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
}

.timeline-content.past {
  opacity: 0.8;
}

.timeline-content.current {
  box-shadow: 0 2px 8px rgba(93, 74, 125, 0.15);
  transform: scale(1.02);
  z-index: 3;
  border-left: none;
}

.timeline-content.future {
  /* Default styling for future stops */
}

/* Time box styling */
.time-box {
  padding: 10px 14px;
  min-width: 80px;
  text-align: center;
  background-color: #f5f5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid rgba(0,0,0,0.05);
}

.time-box.past {
  background-color: #f0f0f0;
}

.time-box.current {
  background-color: #f0ebf7;
}

.time-box.future {
  background-color: #f8f8f8;
}

.time-hour {
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
  line-height: 1.2;
}

.time-period {
  font-size: 0.75rem;
  color: #666;
  margin-top: 2px;
}

/* Stop info styling */
.stop-box {
  flex: 1;
  padding: 10px 14px;
}

.stop-name {
  color: #333;
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 0.9rem;
}

.stop-status {
  font-weight: 400;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.stop-status.on-time {
  color: #2e7d32;
}

.stop-status.delayed {
  color: #d32f2f;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  display: inline-block;
}

.status-dot.on-time {
  background-color: #2e7d32;
}

.status-dot.delayed {
  background-color: #d32f2f;
}

/* Journey progress indicator */
.journey-progress {
  position: absolute;
  left: 24px;
  top: 0;
  width: 3px;
  background-color: #5d4a7d;
  z-index: 2;
}

.timeline-node.current + .journey-progress {
  height: 50%;
  opacity: 0;
}

/* Refresh button styling */
.refresh-box {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgba(93, 74, 125, 0.08);
  border-radius: 20px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.refresh-counter {
  color: #5d4a7d;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
}

.refresh-icon {
  color: #5d4a7d;
  cursor: pointer;
  font-size: 18px;
}

.refresh-icon.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Report inaccuracy link */
.report-link {
  margin-top: 8px;
  text-align: right;
  padding-right: 16px;
}

.report-link a {
  color: #5d4a7d;
  font-size: 0.75rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.report-link a:hover {
  text-decoration: underline;
}

/* Progress indicator dots */
.status-indicators {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px 12px;
}

.status-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: #e0e0e0;
}

.status-indicator.active {
  background-color: #5d4a7d;
  width: 8px;
  height: 8px;
}

/* Current location highlight */
.current-location-label {
  position: absolute;
  right: 10px;
  top: -8px;
  background-color: #5d4a7d;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 0.65rem;
  font-weight: 600;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .timeline-container {
    padding-left: 1rem;
  }
  
  .timeline-container::before {
    left: 18px;
  }
  
  .timeline-node {
    left: -6px;
  }
  
  .timeline-node.current {
    left: -8px;
  }
  
  .journey-progress {
    left: 18px;
  }
  
  .timeline-item {
    padding-left: 1.5rem;
  }
  
  .time-box {
    min-width: 70px;
    padding: 10px;
  }
  
  .stop-box {
    padding: 10px;
  }
} 